<template>
    <div class="change-password">
        <v-container pa-6>
            <password-rule />
            <div class="input-item">
                <span>旧密码</span>
                <input 
                    type="password"
                    v-model="oldPassword"
                    maxlength="20"
                />
            </div>
            <div class="input-item">
                <span>新密码</span>
                <input 
                    type="password"
                    v-model="password"
                    maxlength="20"
                />
            </div>
            <div class="input-item">
                <span>确认密码</span>
                <input 
                    type="password"
                    v-model="passwordConfirm"
                    maxlength="20"
                />
            </div>
        </v-container>
        <v-btn
            class="button-submit"
            rounded
            depressed
            color="primary"
            :disabled="!oldPassword || !password || !passwordConfirm"
            @click="submit"
        >
            提交
        </v-btn>
    </div>
</template>

<script>
import md5 from 'js-md5';
import PasswordRule from '@/components/password-rule';
import {changePassword} from '@/service/service';
const passReg = /^[A-Za-z]{1}(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,19}$/;
export default {
    name: 'ChangePassword',

    components: {
        PasswordRule,
    },

    data() {
        return {
            oldPassword: '',
            password: '',
            passwordConfirm: '',
        }
    },

    methods: {
        submit() {
            if (!passReg.test(this.password)) {
                this.$toast.error('新密码格式错误');
                return;
            }
            if (this.password !== this.passwordConfirm) {
                this.$toast.error('两次密码不一致');
                return;
            }
            this.$loading.show();
            changePassword({
                oldPassword: md5(this.oldPassword).toLocaleUpperCase(),
                newPassword: md5(this.password).toLocaleUpperCase(),
            }).then(() => {
                this.$toast.success('修改成功');
                setTimeout(() => {
                    this.$router.push('/login');
                });
                this.$loading.hide();
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.change-password {
    height: 100%;
    overflow-y: auto;
}
</style>