<template>
<div>
    <div class="passwor-rule">
        <i class="iconfont icon-tishi"></i>
        <span @click="visible = true">查看密码规则</span>
    </div>
    <v-dialog
        v-model="visible"
        persistent
        max-width="250"
    >
        <v-card>
            <v-card-title>
                密码规则
            </v-card-title>
            <v-card-text>
                1、密码必须包含字母和数字；<br />
                2、密码不能含有字符；<br />
                3、密码必须以字母开头；<br />
                4、密码长度为8-20位；
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                depressed
                @click="visible = false"
                style="width: 100%"
            >
                知道了
            </v-btn>
            </v-card-actions>
        </v-card>   
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'PasswordRule',

    data() {
        return {
            visible: false
        }
    },
}
</script>

<style lang="scss" scoped>
.passwor-rule {
    text-align: right;
    color: #999999;
    margin: 14px 0;
    font-size: 13px;
    line-height: 1;
    i {
        vertical-align: middle;
        margin-right: 3px;
        position: relative;
        top: -1px;
    }
}
</style>